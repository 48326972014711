import React from "react";

export type AsideMenuItemProps = {
    name: string;
    href: string;
};

const AsideMenuItem: React.FC<AsideMenuItemProps> = ({ name, href }) => {
    return <a className="header-aside-menu-link" href={href}>{name}</a>;
};

export default AsideMenuItem;
