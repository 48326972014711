import { useContext, useEffect, useRef, useState } from "react";
import { getTendersContractsFile, getTendersFile } from "../../services/tenders";
import { AppDataContext } from "../../contexts/AppDataContext";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { TendersContext } from "../../contexts/TendersContext";

const TendersExportButton = () => {
    const { date } = useContext(AppDataContext);
    const {
        customer,
        supplier,
        subject,
        status,
        budgetType,
        type,
        tradeMethod,
        organizator,
        feature
    } = useContext(TendersContext);
    const params = useParams();
    const districtId = params.id;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const exportRef = useRef<HTMLDivElement | null>(null);
    const options: string[] = ["Заключенные договора с начала года", "Отчет по отфильтрованным объявлениям"];

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
        if (exportRef.current && !exportRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    }

    const downloadContracts = async () => {
        const response = await getTendersContractsFile(dayjs(date).format("YYYY-MM-DD"));
        
        if (!response.isError) {
            const fileUrl = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute("download", `tenders contracts ${dayjs(date).format("DD-MM-YYYY")}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } else {
            console.error('Произошла ошибка при скачивании файла:', response.error);
        }
    };

    const downloadTenders = async () => {
        const response = await getTendersFile(
            dayjs(date).format("YYYY-MM-DD"),
            districtId,
            status.selectedIds, 
            budgetType.selectedIds, 
            customer.selectedIds, 
            subject.ids, 
            supplier.selectedIds, 
            tradeMethod.selectedIds, 
            type.ids, 
            organizator.selectedIds, 
            feature.ids
        );
        
        if (!response.isError) {
            const fileUrl = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute("download", `tenders ${dayjs(date).format("DD-MM-YYYY")}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } else {
            console.error('Произошла ошибка при скачивании файла:', response.error);
        }
    };

    return (
        <div className="tenders-export-wrapper" ref={exportRef}>
            <button
                className="button"
                style={{width: "182px"}}
                onClick={() => setIsOpen(!isOpen)}
            >
                ЭКСПОРТ
            </button>

            {isOpen && 
                <div className="dashboard-switcher-list-wrapper tenders-export">
                    {options.map((option: string) => 
                        <div
                            key={option}
                            className="dashboard-switcher dashboard-switcher-list"
                            onClick={option === "Заключенные договора с начала года" ? downloadContracts : downloadTenders}
                        >
                            {option}
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default TendersExportButton;